import React, { useContext, useEffect } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import translations from '../../translations';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './A_Propos.css';

const A_Propos = () => {
    const { language } = useContext(LanguageContext);
    const t = translations[language];

    useEffect(() => {
        AOS.init({
            duration: 1000,
            easing: 'ease-in-out',
        });
    }, []);

    return (
        <div className="A_Propos" data-aos="fade-up">
            <div className='description_APropos' data-aos="fade-ip">
                <h1 data-aos="fade-left">{t.commitment}</h1>
                <p dangerouslySetInnerHTML={{ __html: t.commitmentDescription }} data-aos="fade-left"></p>
            </div>
            {/* <div className='video_APropos'>
                <iframe
                    src="https://www.youtube.com/embed/4FQE8hVxv"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    data-aos="fade-left"
                ></iframe>
            </div> */}
        </div>
    );
}

export default A_Propos;
