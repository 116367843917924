import React from 'react';
import './Mentions_Légales.css';


const Mentions_Légales = () => {
    
    return (
        <div>
            <h1>Mentions_Légales</h1>
            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Similique, hic expedita rerum iure officia repudiandae beatae nam quas. Nam quos repudiandae earum architecto omnis libero corrupti ipsum natus dolor vitae!</p>
        </div>
    )
}

export default Mentions_Légales;