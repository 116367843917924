import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Home from './pages/Home/Home';
import Vente from './pages/Vente/Vente';
import APropos from './pages/A_Propos/A_Propos';
import Contact from './pages/Contact/Contact';
import VenteProductDetails from './pages/VenteProductDetails/VenteProductDetails';
import Découvrir_La_Ville from './pages/Découvrir_La_Ville/Découvrir_La_Ville';
import Footer from './components/Footer/Footer';
import Error404 from './pages/Error404/Error404';
import Honoraires from './pages/Honoraires/Honoraires';
import Mentions_Légales from './pages/Mentions_Légales/Mentions_Légales';
import { ProductProvider } from './context/ProductContext';
import { LanguageProvider } from './context/LanguageContext';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './App.css';

const App = () => {
    useEffect(() => {
        AOS.init({
            duration: 2000,
            once: false,
            mirror: true
        });
    }, []);

    return (
        <ProductProvider>
            <LanguageProvider>
                <Router>
                    <Routes>
                        <Route path="/" element={<><Navbar threshold={837} /><Home /></>} />
                        <Route path="/Vente" element={<><Navbar threshold={535} /><Vente /></>} />
                        <Route path="/APropos" element={<><Navbar threshold={535} /><APropos /></>} />
                        <Route path="/Contact" element={<><Navbar threshold={535} /><Contact /></>} />
                        <Route path="/Vente/:id" element={<><Navbar threshold={-100} /><VenteProductDetails /></>} />
                        <Route path="/Découvrir_La_Ville" element={<><Navbar threshold={500} /><Découvrir_La_Ville /></>} />
                        <Route path='/Honoraires' element={<><Navbar threshold={300} /><Honoraires /></>} />
                        <Route path='/Mentions_Légales' element={<><Navbar threshold={300} /><Mentions_Légales /></>} />
                        <Route path="*" element={<><Navbar threshold={100} /><Error404 /></>} />
                    </Routes>
                    <Footer />
                </Router>
            </LanguageProvider>
        </ProductProvider>
    );
};

export default App;
