import React from 'react';
import image from '../../images/DSC00001.jpg'
import './Header_Vente.css'


const Header_Vente = () => {
    return (
        <div className="Header_Vente">
            {/* <img src="https://st2.depositphotos.com/1007034/6589/i/450/depositphotos_65897773-stock-photo-modern-house-with-pool.jpg" alt="" /> */}
            <img src={image} alt="" />
        </div>
    );
}

export default Header_Vente;