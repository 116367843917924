import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { LanguageContext } from '../../context/LanguageContext';
import translations from '../../translations';
import './Section2_home.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import image_var from '../../images/image_var.jpg'

const Section2_home = () => {
    const { language } = useContext(LanguageContext);
    const t = translations[language];

    useEffect(() => {
        AOS.init({ duration: 1000 }); // Initialiser AOS avec une durée d'animation de 1000ms
    }, []);

    return (
        <div className="Section2_home" data-aos="fade-up">
            <div className="img-Section2_home" >
                <img src={image_var} alt="" />
            </div>
            <div className="description-Section2_home" data-aos="fade-down">
                <h1>{t.pourquoi}</h1>
                <p>{t.section2Description}</p>
                <Link to={`/Découvrir_La_Ville`}>
                    <button data-aos="fade-up">{t.decouvrir}</button>
                </Link>
            </div>
        </div>
    );
}

export default Section2_home;
