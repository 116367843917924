import React, { useContext, useEffect } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import translations from '../../translations';
import './CartSection_home.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const CartSection_home = () => {
    const { language } = useContext(LanguageContext);
    const t = translations[language];

    useEffect(() => {
        AOS.init({ duration: 2000 }); // Initialiser AOS avec une durée d'animation de 1000ms
    }, []);

    return (
        <div className="CartSection_home" data-aos="fade-up">
            <div className='CartSection_home_title'>
                <h1>{t.ourLocation}</h1>
            </div>
            <div className='CartSection_home_context'>
                <div className="cart_map" data-aos="zoom-in">
                <iframe src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d22037.262213825998!2d5.887057828811179!3d43.1230368764331!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sfr!2sbe!4v1721236509897!5m2!1sfr!2sbe" width="600" height="450" style={{ borderRadius: 10 }}  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d60864.98034836143!2d5.89221155264365!3d43.13132149186186!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDPCsDA3JzUzLjUiTiA1wrA1NicxMi4wIkU!5e0!3m2!1sfr!2sbe!4v1720703586649!5m2!1sfr!2sbe" 
                    width="600" height="450" 
                    style={{ borderRadius: 10 }}  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                </div>
            </div>
        </div>
    );
}

export default CartSection_home;



