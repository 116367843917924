import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useProducts } from '../../context/ProductContext';
import Modal from 'react-responsive-modal';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'react-responsive-modal/styles.css';
import { LanguageContext } from '../../context/LanguageContext';
import translations from '../../translations';
import Error404 from '../../pages/Error404/Error404'; // Assurez-vous que le chemin est correct
import './VenteProductDetails.css';

const VenteProductDetails = () => {
    const { id } = useParams();
    const products = useProducts();
    const product = products.find((p) => p.id === parseInt(id));

    const { language } = useContext(LanguageContext);
    const t = translations[language];

    const [openModal, setOpenModal] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    const openImageModal = (index) => {
        setSelectedImageIndex(index);
        setOpenModal(true);
    };

    const closeImageModal = () => {
        setOpenModal(false);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const renderVideo = (item) => (
        <div className="video-wrapper">
            <video controls={true} autoPlay={false}>
                <source src={item.embedUrl} type="video/mp4" />
                Votre navigateur ne supporte pas la lecture de vidéos.
            </video>
        </div>
    );

    if (!product) {
        return <Error404 />;
    }

    const images = [
        {
            original: product.mainMedia.src,
            thumbnail: product.mainMedia.type === 'image' ? product.mainMedia.src : product.mainMedia.thumbnail,
            description: `${product.type} à ${product.location}`,
            renderItem: product.mainMedia.type === 'video' ? renderVideo : undefined,
            embedUrl: product.mainMedia.type === 'video' ? product.mainMedia.src : undefined
        },
        ...(product.carouselImages || []).map(item => {
            if (item.type === 'image') {
                return { original: item.src, thumbnail: item.src };
            } else if (item.type === 'video') {
                return { original: item.thumbnail, thumbnail: item.thumbnail, embedUrl: item.src, renderItem: renderVideo };
            }
            return null;
        }).filter(item => item !== null)
    ];

    const modalThumbnails = [
        { src: product.mainMedia.type === 'image' ? product.mainMedia.src : product.mainMedia.thumbnail, type: product.mainMedia.type }
    ];

    (product.carouselImages || []).forEach(item => {
        modalThumbnails.push({ src: item.type === 'image' ? item.src : item.thumbnail, type: item.type });
    });

    return (
        <div className="product-details-container">
            <div className="product-images">
                <div className="main-image">
                    {product.mainMedia.type === 'image' ? (
                        <img src={product.mainMedia.src} alt={product.type} onClick={() => openImageModal(0)} />
                    ) : (
                        <video
                            src={product.mainMedia.src}
                            onClick={() => openImageModal(0)}
                            autoPlay={false}
                            style={{ width: '100%' }}
                        >
                            Votre navigateur ne supporte pas la lecture de vidéos.
                        </video>
                    )}
                </div>
                <div className="image-thumbnails">
                    {(product.carouselImages || []).map((item, index) => (
                        item.type === 'image' ? (
                            <img
                                key={index}
                                src={item.src}
                                alt={`Miniature ${index + 1}`}
                                onClick={() => openImageModal(index + 1)}
                            />
                        ) : (
                            <video
                                key={index}
                                src={item.src}
                                onClick={() => openImageModal(index + 1)}
                                style={{ width: '100px', height: '100px', cursor: 'pointer' }}
                                muted
                                loop
                            >
                                Votre navigateur ne supporte pas la lecture de vidéos.
                            </video>
                        )
                    ))}
                </div>
            </div>
            <div className="product-info">
                <div className='product-title'>
                    <h1>{t.sale} {product.type} {product.location}</h1>
                    <h1>{t.price} {product.price.toLocaleString()} €</h1>
                </div>
                <hr />
                <div className='product-details'>
                    <p><b>{t.type}</b> {product.type}</p>
                    <p><b>{t.location}</b> {product.location}</p>
                    <p><b>{t.superficie}</b> {product.superficie}</p>
                    <p><b>{t.land}</b> {product.terrain}</p>
                    <p><b>{t.surfaceloicarre}</b> {product.surfaceloicarre}</p>
                    <p><b>{t.bedrooms}</b> {product.chambres}</p>
                    <p><b>{t.vue}</b> {product.vue}</p>
                    <p><b>{t.ascenseur}</b> {product.ascenseur}</p>
                    <p><b>{t.price}</b> {product.price.toLocaleString()} €</p>
                </div>
                <hr />
            </div>
            <Modal
                open={openModal}
                onClose={closeImageModal}
                center
                classNames={{ modal: 'custom-modal', overlay: 'custom-modal-overlay', closeButton: 'custom-modal-close-button' }}
            >
                <div className="image-carousel-modal">
                    <ImageGallery
                        items={images}
                        startIndex={selectedImageIndex}
                        showPlayButton={false}
                        showThumbnails={false}
                        showFullscreenButton={false}
                        showNav={true}
                    />
                </div>
            </Modal>
        </div>
    );
};

export default VenteProductDetails;
