import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useProducts } from '../../context/ProductContext';
import { LanguageContext } from '../../context/LanguageContext';
import translations from '../../translations';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Section1_Vente.css';

const Section1_Vente = () => {
    const { language } = useContext(LanguageContext);
    const t = translations[language];

    const products = useProducts();
    const [typeFilter, setTypeFilter] = useState("");
    const [priceFilter, setPriceFilter] = useState([0, 10000000]);

    const handleTypeChange = (event) => {
        setTypeFilter(event.target.value);
    };

    const handlePriceChange = (event) => {
        const { name, value } = event.target;
        setPriceFilter((prev) => {
            const newPriceFilter = [...prev];
            if (name === "minPrice") {
                newPriceFilter[0] = Number(value);
            } else {
                newPriceFilter[1] = Number(value);
            }
            return newPriceFilter;
        });
    };

    const filteredProperties = products.filter((property) => {
        return (
            (typeFilter === "" || property.type === typeFilter) &&
            property.price >= priceFilter[0] &&
            property.price <= priceFilter[1]
        );
    });

    useEffect(() => {
        AOS.init({
            duration: 1000,
            easing: 'ease-in-out',
        });
    }, []);

    return (
        <div className="Section1_Vente">
            <h1 data-aos="fade-up">{t.luxuryRealEstate}</h1>

            {/* Filters Section */}
            {/* ... Code for filters here (if needed) ... */}

            <div className='produit_Section1_Vente'>
                {filteredProperties.map((property) => (
                    <div key={property.id} className='element_produit_Section1_Vente' data-aos="fade-right">
                        {property.mainMedia.type === 'image' ? (
                            <img src={property.mainMedia.src} alt={property.type} />
                        ) : (
                            <video
                                src={property.mainMedia.src}
                                autoPlay={false}
                                style={{ width: '100%' }}
                            >
                                Votre navigateur ne supporte pas la lecture de vidéos.
                            </video>
                        )}
                        <div className='type_element_produit_Section1_Vente'>
                            <h2>Vente - {property.type} - {property.location}</h2>
                            <h2>{property.domaine}</h2>
                            <div className='prix-element'>
                                <p><strong>{t.price}</strong> {property.price.toLocaleString()} €</p>
                            </div>
                        </div>
                        <hr />
                        <div className='surface-element'>
                            <p><strong>{t.area}</strong> {property.superficie}</p>
                        </div>
                        <hr />
                        <div className='type-element'>
                            <p><strong>{t.bedrooms}</strong> {property.chambres}</p>
                        </div>
                        <hr />
                        <p><strong>{t.vue}</strong> {property.vue}</p>
                        <Link to={`/Vente/${property.id}`}>
                            <button className='btn-elemet'>{t.learnMore}</button>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Section1_Vente;
