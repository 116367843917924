import React, { useState, useContext, useEffect } from 'react';
import emailjs from 'emailjs-com';
import ReCAPTCHA from 'react-google-recaptcha';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { LanguageContext } from '../../context/LanguageContext';
import translations from '../../translations';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Formulaire_Contact.css';

const Formulaire_Contact = () => {
    const { language } = useContext(LanguageContext);
    const t = translations[language];

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
    });
    const [recaptchaToken, setRecaptchaToken] = useState('');
    const [showSendingMessage, setShowSendingMessage] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showButton, setShowButton] = useState(true);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!recaptchaToken) {
            alert(t.recaptcha);
            return;
        }

        setShowSendingMessage(true);

        console.log('Sending email with the following data:');
        console.log('Service ID:', process.env.REACT_APP_EMAILJS_SERVICE_ID);
        console.log('Template ID:', process.env.REACT_APP_EMAILJS_TEMPLATE_ID);
        console.log('User ID:', process.env.REACT_APP_EMAILJS_USER_ID);
        console.log('Form Data:', formData);
        console.log('reCAPTCHA Token:', recaptchaToken);

        emailjs
            .send(
                process.env.REACT_APP_EMAILJS_SERVICE_ID,
                process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
                {
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    email: formData.email,
                    phone: formData.phone,
                    subject: formData.subject,
                    message: formData.message,
                    'g-recaptcha-response': recaptchaToken,
                },
                process.env.REACT_APP_EMAILJS_USER_ID
            )
            .then((result) => {
                console.log('Email successfully sent!', result.text);

                setShowSendingMessage(false);
                setShowSuccessMessage(true);

                setTimeout(() => {
                    setFormData({
                        firstName: '',
                        lastName: '',
                        email: '',
                        phone: '',
                        subject: '',
                        message: '',
                    });
                    setRecaptchaToken('');

                    setShowSuccessMessage(false);
                    setShowButton(true);
                }, 5000);
            })
            .catch((error) => {
                console.error('Error sending email:', error);
                alert('Error sending email: ' + error.text);
                setShowSendingMessage(false);
                setShowButton(true);
            });
    };

    useEffect(() => {
        AOS.init({
            duration: 1000,
            easing: 'ease-in-out',
        });
    }, []);

    return (
        <div className="Formulaire_Contact" data-aos="fade-up">
            <h1>{t.contactUsForm}</h1>
            <form className="contact-form" onSubmit={handleSubmit}>
                <div className="form-group" data-aos="fade-left">
                    <label htmlFor="firstName">{t.firstName} :</label>
                    <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        placeholder={t.firstName}
                        required
                    />
                </div>
                <div className="form-group" data-aos="fade-left">
                    <label htmlFor="lastName">{t.lastName} :</label>
                    <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        placeholder={t.lastName}
                        required
                    />
                </div>
                <div className="form-group" data-aos="fade-left">
                    <label htmlFor="email">{t.contactEmail} :</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder={t.contactEmail}
                        required
                    />
                </div>
                <div className="form-group" data-aos="fade-left">
                    <label htmlFor="phone">{t.contactPhone} :</label>
                    <input
                        type="tel"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        placeholder={t.contactPhone}
                        required
                    />
                </div>
                <div className="form-group" data-aos="fade-left">
                    <label htmlFor="subject">{t.subject} :</label>
                    <input
                        type="text"
                        id="subject"
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                        placeholder={t.subject}
                        required
                    />
                </div>
                <div className="form-group" data-aos="fade-left">
                    <label htmlFor="message">{t.message} :</label>
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        placeholder={t.message}
                        required
                        rows={5}
                        style={{ resize: 'vertical' }}
                    />
                </div>
                <div className="recaptcha-container" data-aos="fade-left">
                    <ReCAPTCHA
                        sitekey="6LcqTRcqAAAAAOpbSVLvZfbjVd-0AvmqPEiPMyUD"
                        onChange={handleRecaptchaChange}
                        value={recaptchaToken}
                    />
                </div>
                {showSendingMessage && (
                    <div className="sending-message" data-aos="fade-left">
                        <FontAwesomeIcon icon={faCheckCircle} /> {t.sending}
                    </div>
                )}
                {!showSendingMessage && !showSuccessMessage && (
                    <button type="submit" data-aos="fade-left">{t.send}</button>
                )}
                {showSuccessMessage && (
                    <div className="success-message" data-aos="fade-left">
                        <FontAwesomeIcon icon={faCheckCircle} /> {t.success}
                    </div>
                )}
            </form>
        </div>
    );
};

export default Formulaire_Contact;
