import React, { useState, useEffect  } from 'react';
import imageBandol from '../../images/Bandol.jpg'
import imageSanary from '../../images/Sanary.webp'
import imageSixFourslesPlages from '../../images/SIX-FOURS-LES-PLAGES.jpg'
import imageToulon from '../../images/Six-Fours-les-Plages.webp'
import imageHyèresEtCarqueiranne from '../../images/Hyères et Carqueiranne.jpg'
import imageLavandou from '../../images/Lavandou et à Bormes les mimosas.jpg'
import './Découvrir_La_Ville.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const cardsData = [
    {
        id: 1,
        imgSrc: imageBandol,
        text: `
            <div class='modalContentInfo'>
                <div class='partie1_modalContentInfo'>
                    <span><b>Cadre de Vie et Commodités:</b></span> Bandol est une ville idéale pour ceux qui recherchent une qualité
                    de vie élevée dans un cadre méditerranéen. Elle offre une multitude d'activités de loisirs, des
                    marchés locaux animés, des boutiques charmantes, et des écoles de qualité. La proximité de grandes
                    villes comme Toulon et Marseille, accessibles en train ou en voiture, ajoute à l'attrait de Bandol
                    pour les familles et les professionnels.
                </div>
                <br />
                <br />
                <div class='partie2_modalContentInfo'>
                    <span><b>Endroits incontournables :</b></span>
                    <br />
                    <ul>
                        <li>
                            <span><b>Les Marchés Provençaux :</b></span> Les marchés hebdomadaires proposent des produits locaux
                            frais, de l'artisanat et des spécialités régionales, créant une ambiance authentique et
                            conviviale.
                        </li>
                        <li>
                            <span><b>Port de Bandol :</b></span> Un port de plaisance animé avec de nombreux restaurants, cafés et
                            boutiques, parfait pour les promenades en soirée.
                        </li>
                        <li>
                            <span><b>Plage de Renécros :</b></span> Une magnifique plage en forme de baie, idéale pour la baignade et les
                            activités nautiques.
                        </li>
                        <li>
                            <span><b>Domaine viticole de Bandol :</b></span> La région est célèbre pour ses vins, et les domaines viticoles
                            offrent des dégustations et des visites guidées.
                        </li>
                        <li>
                            <span><b>Île de Bendor :</b></span> Accessible en bateau, cette petite île privée offre des plages, des restaurants
                            et une vue imprenable sur la mer Méditerranée.
                        </li>
                        <li>
                            <span><b>Sentier du Littoral :</b></span> Un sentier de randonnée qui longe la côte, offrant des vues
                            spectaculaires sur la mer et les falaises.
                        </li>
                        <li>
                            <span><b>Le Casino de Bandol :</b></span> Pour ceux qui aiment le divertissement nocturne, le casino offre des
                            jeux, des spectacles et des soirées à thème.
                        </li>
                    </ul>
                </div>
                <br />
                <br />
                <div class='partie3_modalContentInfo'>
                    <span><b>Quartiers Résidentiels Prisés:</b></span>
                    <ul>
                        <li>
                            <span><b>Le Capelan :</b></span> Un quartier résidentiel calme, proche de la mer, avec de belles villas et des
                            vues panoramiques.
                        </li>
                        <li>
                            <span><b>Pierreplane :</b></span> Connu pour ses grandes propriétés et sa tranquillité, c’est un quartier très
                            recherché pour sa qualité de vie.
                        </li>
                        <li>
                            <span><b>Bellevue :</b></span> Un quartier résidentiel avec une excellente vue sur la mer, très prisé pour son
                            cadre paisible et ses belles maisons.
                        </li>
                        <li>
                            <span><b>Escourche :</b></span> Un quartier résidentiel populaire pour sa proximité avec le centre-ville et ses
                            belles propriétés.
                        </li>
                        <li>
                            <span><b>La Peyrière :</b></span> Un quartier calme et verdoyant, avec de grandes maisons et des jardins
                            spacieux.
                        </li>
                    </ul>
                </div>
                <br />
                <br />
                <p>
                    Avec son mélange unique de charme provençal, de beautés naturelles et d'infrastructures modernes,
                    Bandol représente un choix attrayant pour ceux qui cherchent à s'installer dans une ville côtière
                    dynamique et accueillante.
                </p>
            </div>
        `,
        btn: 'Vivre à Bandol'
    },
    {
        id: 2,
        imgSrc: imageSanary,
        text: `
            <div class='modalContentInfo'>
                <div class='partie1_modalContentInfo'>
                    <span><b>Cadre de Vie et Commodités:</b></span> Sanary-sur-Mer est une ville idéale pour ceux qui recherchent une
                        qualité de vie élevée dans un cadre méditerranéen. Elle offre une multitude d'activités de loisirs, des
                        marchés locaux animés, des boutiques charmantes, et des établissements scolaires de qualité. La
                        ville est également bien desservie par les transports en commun, facilitant l'accès aux villes voisines
                        comme Toulon et Marseille.
                </div>
                <br />
                <br />
                <div class='partie2_modalContentInfo'>
                    <span><b>Endroits incontournables :</b></span>
                    <br />
                    <ul>
                        <li>
                            <span><b>Plage de Portissol :</b></span> Une plage de sable fin très populaire, idéale pour la baignade, avec des
                            restaurants et des bars à proximité.
                        </li>
                        <li>
                            <span><b>Théâtre Galli :</b></span> Un centre culturel offrant une variété de spectacles, de concerts et de pièces
                            de théâtre tout au long de l'année.
                        </li>
                        <li>
                            <span><b>Jardin Exotique :</b></span>Un parc luxuriant avec une grande variété de plantes exotiques, parfait
                            pour des promenades tranquilles en famille.
                        </li>
                        <li>
                            <span><b>Le Petit Train Touristique :</b></span> Une façon agréable de découvrir la ville et ses environs, en
                            profitant de vues panoramiques et de commentaires informatifs.
                        </li>
                        <li>
                            <span><b>Port de Sanary-sur-Mer</b></span> Un port pittoresque avec des bateaux de pêche colorés, idéal
                            pour les promenades, entouré de cafés et de restaurants animés.
                        </li>
                        <li>
                            <span><b>Marché de Sanary :</b></span> Élu plus beau marché de France en 2018, il offre une variété de
                            produits locaux frais, des spécialités provençales et des produits artisanaux.
                        </li>
                        <li>
                            <span><b>Église Saint-Nazaire :</b></span> Une église historique, connue pour son architecture néo-gothique et
                            ses vitraux magnifiques.
                        </li>
                        <li>
                            <span><b>Chapelle Notre-Dame de Pitié :</b></span> Située sur une colline, cette chapelle offre des vues
                            panoramiques imprenables sur la mer et les environs.
                        </li>
                        <li>
                            <span><b>Sentier du Littoral :</b></span> Un sentier de randonnée qui suit la côte, offrant des vues
                            spectaculaires sur la mer Méditerranée et les falaises escarpées.
                        </li>
                    </ul>
                </div>
                <br />
                <br />
                <div class='partie3_modalContentInfo'>
                    <span><b>Quartiers Résidentiels Prisés:</b></span>
                    <ul>
                        <li>
                            <span><b>Le Lançon :</b></span> Un quartier résidentiel paisible avec de magnifiques vues sur la mer et une
                            atmosphère calme.
                        </li>
                        <li>
                            <span><b>La Cride :</b></span> Connu pour ses belles propriétés et sa proximité avec la plage, c’est un quartier
                            très prisé des familles.
                        </li>
                        <li>
                            <span><b>Le Baou :</b></span> Situé près du centre-ville, ce quartier combine commodité et tranquillité, avec des
                            maisons charmantes et des jardins spacieux.
                        </li>
                        <li>
                            <span><b>Portissol :</b></span> Un quartier haut de gamme proche de la plage de Portissol, très recherché pour
                            ses villas et ses vues imprenables sur la mer.
                        </li>
                        <li>
                            <span><b>Beaucours :</b></span> Un quartier résidentiel calme, apprécié pour son cadre verdoyant et ses grandes
                            propriétés.
                        </li>
                    </ul>
                </div>
                <br />
                <br />
                <p>
                    Avec son mélange unique de charme provençal, de beautés naturelles et d'infrastructures modernes,
                    Bandol représente un choix attrayant pour ceux qui cherchent à s'installer dans une ville côtière
                    dynamique et accueillante.
                </p>
            </div>
        `,
        btn: 'Vivre à Sanary'
    },
    {
        id: 3,
        imgSrc: imageSixFourslesPlages ,
        text: `
            <div class='modalContentInfo'>
                <div class='partie1_modalContentInfo'>
                    <span><b>Cadre de Vie et Commodités:</b></span> Six-Fours-les-Plages est une ville attrayante pour ceux qui cherchent
                        à vivre dans un environnement côtier dynamique. Elle offre une variété d'activités de loisirs, des
                        marchés locaux animés, des restaurants et des boutiques. La ville est également bien connectée,
                        facilitant les déplacements vers les villes voisines comme Toulon et Marseille.
                </div>
                <br />
                <br />
                <div class='partie2_modalContentInfo'>
                    <span><b>Endroits incontournables :</b></span>
                    <br />
                    <ul>
                        <li>
                            <span><b>Île des Embiez :</b></span>Accessible en bateau, cette île offre des plages magnifiques, des sentiers de
                            randonnée et des activités nautiques. C'est un endroit idéal pour une excursion d'une journée.
                        </li>
                        <li>
                            <span><b>Fort de Six-Fours :</b></span> Un site historique situé sur une colline, offrant des vues panoramiques
                            sur la région et la mer Méditerranée.
                        </li>
                        <li>
                            <span><b>Plage de Bonnegrâce :</b></span> Une plage de sable fin populaire auprès des familles et des amateurs
                            de sports nautiques.
                        </li>
                        <li>
                            <span><b>Parc de la Méditerranée :</b></span> Un parc familial avec des aires de jeux, des jardins
                            méditerranéens et des sentiers pour des promenades tranquilles.
                        </li>
                        <li>
                            <span><b>Collégiale Saint-Pierre :</b></span>Une église historique située sur une colline, avec des vues
                            imprenables sur la mer et les environs.
                        </li>
                        <li>
                            <span><b>Île du Gaou :</b></span> Un petit îlot accessible à pied, parfait pour les promenades et les pique-niques
                            avec des vues spectaculaires sur la mer.
                        </li>
                        <li>
                            <span><b>Sentier du Littoral :</b></span> Un sentier de randonnée le long de la côte offrant des vues à couper le
                            souffle sur la mer et les falaises.
                        </li>
                        <li>
                            <span><b>Marché Provençal :</b></span> Un marché hebdomadaire proposant des produits locaux frais, des
                            spécialités régionales et de l'artisanat.
                        </li>
                        <li>
                            <span><b>École de Voile de Six-Fours :</b></span> Pour les amateurs de sports nautiques, cette école offre des
                            cours de voile et de planche à voile adaptés à tous les niveaux.
                        </li>
                        <li>
                            <span><b>Centre des Congrès de la Villa Nuraghes :</b></span> Un centre culturel et événementiel accueillant
                            des expositions, des conférences et des concerts.
                        </li>
                    </ul>
                </div>
                <br />
                <br />
                <div class='partie3_modalContentInfo'>
                    <span><b>Quartiers Résidentiels Prisés:</b></span>
                    <ul>
                        <li>
                            <span><b>Le Brusc :</b></span> Un quartier pittoresque avec un port charmant, parfait pour les amateurs de
                            nautisme et les familles recherchant un cadre paisible proche de la mer.
                        </li>
                        <li>
                            <span><b>Les Lônes :</b></span> Proche du centre-ville et de la plage, ce quartier est très prisé pour sa
                            commodité et son ambiance résidentielle.
                        </li>
                        <li>
                            <span><b>La Coudoulière :</b></span> Connu pour ses espaces verts, ses lacs et sa proximité avec la plage, c’est
                            un quartier idéal pour les familles.
                        </li>
                        <li>
                            <span><b>Les Playes :</b></span> Un quartier résidentiel calme avec des maisons spacieuses, apprécié pour sa
                            tranquillité et sa proximité avec les commodités.
                        </li>
                        <li>
                            <span><b>Le Cap Nègre :</b></span> Un quartier résidentiel haut de gamme offrant des vues imprenables sur la
                            mer et un accès facile aux plages.
                        </li>
                    </ul>
                </div>
                <br />
                <br />
                <p>
                    Avec son mélange unique de plages magnifiques, de sites historiques et de quartiers résidentiels
                    attrayants, Six-Fours-les-Plages est une destination idéale pour ceux qui recherchent une qualité de
                    vie élevée dans un cadre méditerranéen.
                </p>
            </div>
        `,
        btn: 'Vivre à Six-Fours-Les-Plages:'
    },
    {
        id: 4,
        imgSrc: imageToulon,
        text: `
            <div class='modalContentInfo'>
                <div class='partie1_modalContentInfo'>
                    <span><b>Cadre de Vie et Commodités:</b></span>Toulon est une ville dynamique avec une riche histoire maritime et
                    culturelle. Elle offre une variété d'activités de loisirs, des marchés animés, des établissements
                    scolaires de qualité et des infrastructures modernes. Sa position géographique permet un accès
                    facile aux montagnes et à la mer, offrant ainsi un cadre de vie idéal pour les familles et les
                    professionnels.
                </div>
                <br />
                <br />
                <div class='partie2_modalContentInfo'>
                    <span><b>Endroits incontournables :</b></span>
                    <br />
                    <ul>
                        <li>
                            <span><b>Mont Faron :</b></span>Accessible par téléphérique, ce mont offre des vues spectaculaires sur la ville
                            et la mer. Il dispose également de sentiers de randonnée et d’un zoo.
                        </li>
                        <li>
                            <span><b>Opéra de Toulon :</b></span> : Un bâtiment historique qui accueille des opéras, des ballets et des
                            concerts, ajoutant une touche culturelle à la ville.
                        </li>
                        <li>
                            <span><b>Port de Toulon :</b></span> Un port animé avec de nombreux restaurants, boutiques, et un marché aux
                            poissons quotidien.
                        </li>
                        <li>
                            <span><b>Musée National de la Marine :</b></span> Un musée dédié à l'histoire maritime de la France, situé sur
                            le port.
                        </li>
                        <li>
                            <span><b>Plages du Mourillon :</b></span> Une série de plages urbaines aménagées avec des aires de jeux, des
                            restaurants et des activités nautiques.
                        </li>
                        <li>
                            <span><b>Place de la Liberté :</b></span> Une place centrale avec une fontaine impressionnante, entourée de
                            cafés et de boutiques.
                        </li>
                        <li>
                            <span><b>Cours Lafayette :</b></span> Un marché en plein air offrant des produits locaux, des fruits de mer, des
                            épices et de l'artisanat.
                        </li>
                        <li>
                            <span><b>Stade Mayol :</b></span> Le stade de rugby de Toulon, où l'on peut assister à des matchs passionnants
                            de l'équipe locale.
                        </li><li>
                            <span><b>Théâtre Liberté :</b></span> Un centre culturel proposant une programmation variée de spectacles, de
                            concerts et de pièces de théâtre.
                        </li><li>
                            <span><b>Parc des Lices :</b></span> Un grand parc urbain parfait pour les promenades, les pique-niques et les
                            activités en famille.
                        </li>
                    </ul>
                </div>
                <br />
                <br />
                <div class='partie3_modalContentInfo'>
                    <span><b>Quartiers Résidentiels Prisés:</b></span>
                    <ul>
                        <li>
                            <span><b>Le Mourillon :</b></span> Un quartier très prisé proche des plages, avec de nombreux commerces,
                            restaurants et une ambiance familiale.
                        </li>
                        <li>
                            <span><b>Cap Brun :</b></span> Un quartier résidentiel haut de gamme offrant des vues imprenables sur la mer
                            et des propriétés luxueuses.
                        </li>
                        <li>
                            <span><b>La Serinette :</b></span> Un quartier calme et verdoyant, idéal pour les familles avec ses parcs et ses
                            écoles de qualité.
                        </li>
                        <li>
                            <span><b>Les Lices :</b></span> Proche du centre-ville, ce quartier est apprécié pour sa commodité et son charme
                            historique.
                        </li>
                        <li>
                            <span><b>Le Baou :</b></span> Situé sur les hauteurs de Toulon, ce quartier offre des vues panoramiques et une
                            atmosphère paisible.
                        </li>
                    </ul>
                </div>
                <br />
                <br />
                <p>
                    Avec son mélange de tradition et de modernité, ses plages, ses quartiers résidentiels attrayants et ses
                    nombreuses activités culturelles et de loisirs, Toulon est une destination de choix pour ceux qui
                    cherchent à s'installer dans une ville dynamique et ensoleillée.
                </p>
            </div>
        `,
        btn: 'Vivre à Toulon'
    },
    {
        id: 5,
        imgSrc: imageHyèresEtCarqueiranne,
        text: `
            <div class='modalContentInfo'>
                <div class='partie1_modalContentInfo'>
                    <span><b>Cadre de Vie et Commodités:</b></span>Hyères et Carqueiranne offrent un cadre de vie exceptionnel avec un
                    climat méditerranéen agréable. Ces villes proposent une multitude d'activités de loisirs, des marchés
                    locaux animés, des établissements scolaires de qualité et des infrastructures modernes. Bien
                    connectées, elles facilitent les déplacements vers les villes voisines comme Toulon et Marseille, tout
                    en offrant une ambiance paisible et résidentielle. Depuis Hyères, vous êtes à seulement 45 minutes
                    de Saint Tropez.
                </div>
                <br />
                <br />
                <div class='partie2_modalContentInfo'>
                    <span><b>Endroits incontournables :</b></span>
                    <br />
                    <ul>
                        <li>
                            <span><b>Îles d'Hyères (Porquerolles, Port-Cros, Île du Levant) :</b></span>Des destinations paradisiaques
                            idéales pour des excursions d'une journée, offrant des plages de sable blanc, des sentiers de
                            randonnée et des eaux cristallines.
                        </li>
                        <li>
                            <span><b>Plage de l'Almanarre :</b></span> : Située à Hyères, cette plage est célèbre pour ses conditions parfaites
                            pour la planche à voile et le kitesurf.
                        </li>
                        <li>
                            <span><b>Port de Hyères :</b></span> Un port animé avec des restaurants, des cafés et des boutiques, parfait
                            pour les promenades en soirée.
                        </li>
                        <li>
                            <span><b>Port des Salettes à Carqueiranne :</b></span> Un charmant port de plaisance, idéal pour les sorties en
                            bateau et les balades en bord de mer.
                        </li>
                        <li>
                            <span><b>Vieille Ville d'Hyères :</b></span> Un quartier historique avec des rues pavées, des bâtiments
                            médiévaux et une atmosphère unique.
                        </li>
                        <li>
                            <span><b>Musée de la Mine de Cap Garonne :</b></span> Situé à Carqueiranne, ce site historique propose des
                            visites guidées pour découvrir l'histoire minière de la région.
                        </li>
                        <li>
                            <span><b>Parc Olbius Riquier :</b></span> Un grand parc à Hyères avec des jardins exotiques, des aires de jeux
                            pour enfants et des espaces verts pour des promenades tranquilles.
                        </li>
                        <li>
                            <span><b>Mont des Oiseaux :</b></span> Entre Hyères et Carqueiranne, ce massif offre des sentiers de
                            randonnée avec des vues panoramiques sur la mer et la campagne environnante.
                        </li><li>
                            <span><b>Plage de l'Almanarre :</b></span> Une des plages les plus populaires, idéale pour les activités
                            nautiques et les bains de soleil.
                        </li><li>
                            <span><b>Marchés Provençaux :</b></span> Les marchés hebdomadaires à Hyères et Carqueiranne proposent
                            des produits locaux frais, des spécialités régionales et de l'artisanat.
                        </li>
                        <li>
                            <span><b>Le Théâtre Denis à Hyères :</b></span> Un centre culturel proposant des spectacles, des concerts et
                            des pièces de théâtre.
                        </li><li>
                            <span><b>Parc Saint-Vincent à Carqueiranne :</b></span> Un grand parc verdoyant parfait pour les
                            promenades, les pique-niques et les activités en famille.
                        </li><li>
                            <span><b>Club Nautique de Carqueiranne :</b></span> Offrant des cours de voile, de planche à voile et de
                            kitesurf pour tous les niveaux.
                        </li>
                        <li>
                            <span><b>Le Petit Train Touristique de Hyères :</b></span> Une manière agréable de découvrir la ville et ses
                            environs, avec des commentaires informatifs.
                        </li>
                    </ul>
                </div>
                <br />
                <br />
                <div class='partie3_modalContentInfo'>
                    <span><b>Quartiers Résidentiels Prisés:</b></span>
                    <ul>
                        <li>
                            <span><b>Costebelle :</b></span> Un quartier résidentiel à Hyères offrant une atmosphère paisible et de belles
                            vues.
                        </li>
                        <li>
                            <span><b>Le Canebas :</b></span> À Carqueiranne, ce quartier résidentiel est apprécié pour ses belles propriétés
                            et sa proximité avec la mer.
                        </li>
                        <li>
                            <span><b>Les Pins Penchés :</b></span> Entre Hyères et Carqueiranne, ce quartier est réputé pour ses vues
                            panoramiques et son cadre tranquille.
                        </li>
                        <li>
                            <span><b>La Capte :</b></span> À Hyères, proche de la plage, idéal pour les amateurs de mer et de soleil.
                        </li>
                        <li>
                            <span><b>Font Brun :</b></span> Un quartier résidentiel calme et verdoyant à Carqueiranne, parfait pour les
                            familles.
                        </li><li>
                            <span><b>Les Palmiers :</b></span> À Hyères, un quartier verdoyant avec de nombreuses commodités et espaces
                            verts.
                        </li><li>
                            <span><b>Le Paradis :</b></span> À Carqueiranne, un quartier haut de gamme avec des villas et des jardins
                            spacieux.
                        </li><li>
                            <span><b>La Moutonne :</b></span> Entre Hyères et Carqueiranne, apprécié pour sa tranquillité et sa proximité
                            des commodités.
                        </li><li>
                            <span><b>Le Mont des Oiseaux :</b></span> A Hyères, l’un des quartiers les plus prisé, domaine fermé.
                        </li>
                    </ul>
                </div>
                <br />
                <br />
                <p>
                    Avec leur cadre naturel exceptionnel, leurs plages magnifiques, leurs quartiers résidentiels attractifs
                    et leurs nombreuses activités culturelles et de loisirs, Hyères et Carqueiranne sont des destinations
                    idéales pour ceux qui cherchent à s'installer dans des villes côtières accueillantes et dynamiques.
                </p>
            </div>
        `,
        btn: 'Vivre à Hyères et Carqueiranne'
    },
    {
        id: 6,
        imgSrc: imageLavandou,
        text: `
            <div class='modalContentInfo'>
                <div class='partie1_modalContentInfo'>
                    <span><b>Cadre de Vie et Commodités:</b></span>Le Lavandou et Bormes-les-Mimosas offrent une qualité de vie
                    exceptionnelle dans un cadre naturel magnifique. Ces villes côtières proposent une variété
                    d'activités de loisirs, des marchés locaux animés, des établissements scolaires de qualité et des
                    infrastructures modernes. Vous y retrouverez de sublimes plages, plus besoin de partir aux maldives
                    !
                </div>
                <br />
                <br />
                <div class='partie2_modalContentInfo'>
                    <span><b>Endroits incontournables :</b></span>
                    <br />
                    <ul>
                        <li>
                            <span><b>Plages du Lavandou :</b></span>Le Lavandou possède 12 plages magnifiques, allant des plages de
                            sable fin aux petites criques isolées, idéales pour la baignade, les activités nautiques et les
                            bains de soleil.
                        </li>
                        <li>
                            <span><b>Village Médiéval de Bormes-les-Mimosas :</b></span> : Un village perché, célèbre pour ses ruelles
                            pavées, ses maisons en pierre et ses jardins fleuris de mimosas.
                        </li>
                        <li>
                            <span><b>Port de Bormes :</b></span> Un port de plaisance pittoresque avec des restaurants, des cafés et des
                            boutiques, parfait pour les promenades en soirée.
                        </li>
                        <li>
                            <span><b>Îles d'Or :</b></span> Les îles de Port-Cros et du Levant, accessibles en bateau, offrent des plages
                            paradisiaques, des sentiers de randonnée et une faune et une flore exceptionnelles.
                        </li>
                        <li>
                            <span><b>Fort de Brégançon :</b></span> Résidence estivale officielle du Président de la République, ce site
                            historique offre des visites guidées et des vues panoramiques sur la mer.
                        </li>
                        <li>
                            <span><b>Sentier du Littoral :</b></span> Un sentier de randonnée qui longe la côte, offrant des vues
                            spectaculaires sur la mer Méditerranée et les falaises escarpées.
                        </li>
                        <li>
                            <span><b>Le Jardin d'Elie :</b></span> Un jardin exotique à Bormes-les-Mimosas, avec une grande variété de
                            plantes méditerranéennes et tropicales.
                        </li>
                        <li>
                            <span><b>Plage de Saint-Clair :</b></span> Une des plages les plus populaires du Lavandou, idéale pour la
                            baignade et les activités nautiques.
                        </li><li>
                            <span><b>Marché Provençal :</b></span> Les marchés hebdomadaires au Lavandou et à Bormes-les-Mimosas
                            proposent des produits locaux frais, des spécialités régionales et de l'artisanat.
                        </li><li>
                            <span><b>Le Fort de Brégançon :</b></span> Offrant des visites guidées et des vues panoramiques sur la mer
                            Méditerranée.
                        </li>
                        <li>
                            <span><b>Le Jardin des Oliviers :</b></span> Un espace vert à Bormes-les-Mimosas, parfait pour les
                            promenades et les pique-niques.
                        </li><li>
                            <span><b>Centre Nautique du Lavandou :</b></span> Proposant des cours de voile, de planche à voile et de
                            kitesurf pour tous les niveaux.
                        </li><li>
                            <span><b>Le Théâtre de Verdure du Lavandou :</b></span> Un centre culturel proposant des spectacles, des
                            concerts et des événements tout au long de l'année.
                        </li>
                    </ul>
                </div>
                <br />
                <br />
                <div class='partie3_modalContentInfo'>
                    <span><b>Quartiers Résidentiels Prisés:</b></span>
                    <ul>
                        <li>
                            <span><b>Saint-Clair :</b></span> Un quartier résidentiel au Lavandou, proche de la plage de Saint-Clair,
                            apprécié pour sa tranquillité et ses vues sur la mer.
                        </li>
                        <li>
                            <span><b>Le Cap Nègre :</b></span> Un quartier résidentiel haut de gamme entre Le Lavandou et Bormes-les-
                            Mimosas, offrant des villas luxueuses et des vues imprenables.
                        </li>
                        <li>
                            <span><b>La Favière :</b></span> À Bormes-les-Mimosas, proche du port, ce quartier est très prisé pour sa
                            commodité et son ambiance conviviale.
                        </li>
                        <li>
                            <span><b>Le Pin de Bormes :</b></span> Un quartier résidentiel calme et verdoyant, idéal pour les familles.
                        </li>
                        <li>
                            <span><b>Cavalière :</b></span> Un quartier résidentiel du Lavandou, connu pour ses belles plages et ses
                            propriétés exclusives.
                        </li><li>
                            <span><b>Le Haut Bormes :</b></span>Un quartier offrant des vues panoramiques sur la mer et le village
                            médiéval, avec des maisons de caractère.
                        </li><li>
                            <span><b>Le Cap Bénat :</b></span> A Bormes les Mimosas, zone résidentielle haut de gamme réputée pour sa
                            beauté naturelle, sa tranquillité et ses propriétés exclusives. Le Cap Bénat est souvent
                            comparé à des destinations de prestige en raison de son environnement exceptionnel, de ses
                            villas luxueuses et de son accès direct à la mer.
                        </li>
                    </ul>
                </div>
                <br />
                <br />
                <p>
                    Avec leur cadre naturel exceptionnel, leurs plages magnifiques, leurs quartiers résidentiels attractifs
                    et leurs nombreuses activités culturelles et de loisirs, Le Lavandou et Bormes-les-Mimosas sont des
                    destinations idéales pour ceux qui cherchent à s'installer dans des villes côtières accueillantes et
                    dynamiques.
                </p>
            </div>
        `,
        btn: 'Vivre au Lavandou et à Bormes les mimosas'
    },

];

const Decouvrir_La_Ville = () => {
    const [modalContent, setModalContent] = useState(null);

    useEffect(() => {
        AOS.init({
            duration: 1000,
            easing: 'ease-in-out',
        });
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleCardClick = (text) => {
        setModalContent(text);
    };

    const closeModal = () => {
        setModalContent(null);
    };

    return (
        <div className="decouvrir-la-ville">
            <h1 data-aos="fade-down">Vivre dans le Var</h1>
            <p data-aos="fade-right">Vivre dans le Var, c'est choisir un cadre de vie exceptionnel où la beauté naturelle rencontre un
                climat méditerranéen doux et ensoleillé. Cette région offre une qualité de vie incomparable, avec
                ses plages paradisiaques, ses villages pittoresques, et ses nombreuses activités de loisirs en plein air.
                Le Var est une destination idéale pour ceux qui recherchent tranquillité, élégance et un
                environnement préservé.
            </p>
            <div className="cards-container" data-aos="fade-left">
                {cardsData.map(card => (
                    <div key={card.id} className="card" onClick={() => handleCardClick(card.text)} >
                        <img src={card.imgSrc} alt="" className="card-image" />
                        <div className="button-overlay">
                            <button className="read-more-button">{card.btn}</button>
                        </div>
                    </div>
                ))}
            </div>
            {modalContent && (
                <div className="modal" onClick={closeModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <span className="close-button" onClick={closeModal}>&times;</span>
                        <div dangerouslySetInnerHTML={{ __html: modalContent }} />
                    </div>
                </div>
            )}
        </div>
    );
}

export default Decouvrir_La_Ville;