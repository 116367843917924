import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useProducts } from '../../context/ProductContext';
import { LanguageContext } from '../../context/LanguageContext';
import translations from '../../translations';
import './Section3_home.css';


const Section3_home = () => {
    const [modalVisible, setModalVisible] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const products = useProducts();
    const { language } = useContext(LanguageContext);
    const t = translations[language];

    

    const openModal = (url) => {
        setVideoUrl(url);
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
        setVideoUrl('');
    };

    return (
        <>

        
        <div className="Section3_home" data-aos="fade-up">
            <div className='description_Section3_home'>
                <h1>{t.sales}</h1>
                <p>{t.salesDescription}</p>
            </div>
            <div className='videos_Section3_home'>
                {products.slice(0, 3).map((product) => (
                    <div key={product.id} className='img_Section3_home' data-aos="fade-right">
                        <img src={product.carousselHome} alt={product.type} />
                        <div className="button-container">
                            {product.carouselImages && product.carouselImages.some(item => item.type === 'video') && (
                                <button onClick={() => openModal(product.carouselImages.find(item => item.type === 'video').src)}>
                                    {t.viewVideo}
                                </button>
                            )}
                            <Link to={`/Vente/${product.id}`} data-aos="fade-up">
                                <button>{t.viewDetails}</button>
                            </Link>
                        </div>
                    </div>
                ))}
            </div>

            
        </div>
        {modalVisible && (
            <div className="modal" onClick={closeModal}>
                <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                    <span className="close" onClick={closeModal}>&times;</span>
                    <video controls>
                        <source src={videoUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        )}
        </>
    );
};

export default Section3_home;
