import React, { useContext, useEffect } from 'react';
import translations from '../../translations';
import { LanguageContext } from '../../context/LanguageContext';
import './Footer.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Footer = () => {
    const { language } = useContext(LanguageContext);
    const t = translations[language];

    useEffect(() => {
        AOS.init({ duration: 2000 }); // Initialiser AOS avec une durée d'animation de 1000ms
    }, []);

    return (
        <div className="Footer" data-aos="fade-up">
            <h1 className="logo">Lentz Immobilier</h1>
            <hr />
            <div className="footer_content">
                {/* <div className="footer_section about" data-aos="fade-right">
                    <p>{t.about}</p>
                </div> */}
                <div className="footer_section social" data-aos="fade-left">
                    <h2>{t.followUs}</h2>
                    <ul className="social_links">
                        <li><a href="https://www.facebook.com/people/Corentin-Lentz-Lentz-Immobilier/61558406570509/"><i className="fab fa-facebook-f"></i> Facebook</a></li>
                        <li><a href="https://www.instagram.com/corentinlentz/?utm_source=qr"><i className="fab fa-instagram"></i> Instagram</a></li>
                        <li><a href="https://www.linkedin.com/in/corentin-lentz-0872b51a7/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"><i className="fab fa-linkedin-in"></i> LinkedIn</a></li>
                    </ul>
                </div>
                <div className="footer_section contact" data-aos="fade-up">
                    <h2>{t.contactUs}</h2>
                    <ul>
                        <li><i className="fas fa-phone"></i> {t.phone}</li>
                        <li><i className="fas fa-envelope"></i> {t.email}</li>
                        <li><i className="fas fa-map-marker-alt"></i> {t.address}</li>
                    </ul>
                </div>
            </div>
            <div className="footer_bottom" >
                <div className='polique' >
                    <a href="">{t.honoraires}</a>
                    <a href="">{t.mentionsLégales}</a>
                </div>
                &copy; {new Date().getFullYear()} MonImmobilier | {t.allRightsReserved}
            </div>
        </div>
    );
}

export default Footer;
