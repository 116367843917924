import React, { useContext } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import translations from '../../translations';
import image_var from '../../images/image_var.jpg'
import './Section1_home.css';

const Section1_home = () => {
    const { language } = useContext(LanguageContext);
    const t = translations[language];

    return (
        <div className="Section1_home" data-aos="fade-up">
            <h1>{t.section1Title}</h1>
            <p dangerouslySetInnerHTML={{ __html: t.section1Description }}></p>
        </div>
    );
}

export default Section1_home;