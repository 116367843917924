import React, { useContext, useEffect } from 'react';
import Slider from 'react-slick';
import { useProducts } from '../../context/ProductContext';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Header_home.css';
import { LanguageContext } from '../../context/LanguageContext';
import AOS from 'aos';
import 'aos/dist/aos.css';

const HeaderHome = () => {
  const { language } = useContext(LanguageContext);
  const products = useProducts(); // Récupérer les produits depuis le contexte

  useEffect(() => {
    AOS.init({
      duration: 1500, // Durée de l'animation en ms
      once: false,     // Animer uniquement une fois
      mirror: true   // Ne pas répéter l'animation en faisant défiler vers le haut
    });
  }, []);

  const getPropertyTypeTranslation = (type) => {
    switch (type) {
      case 'Villa':
        return language === 'fr' ? 'Villa' : 'Villa';
      case 'Maison':
        return language === 'fr' ? 'Maison' : 'House';
      case 'Appartement':
        return language === 'fr' ? 'Appartement' : 'Apartment';
      default:
        return type;
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };

  if (!products || !Array.isArray(products)) {
    return <div>Loading...</div>; // Affiche un message de chargement ou une alternative appropriée
  }

  return (
    <div className="header">
      <Slider {...settings}>
        {products.map((product, index) => (
          <div key={index} className="carousel-slide" data-aos="fade-up">
            <img src={product.carousselHome} alt={`Slide ${index}`} className="carousel-image" />
            <div className="info-overlay">
              <div className="info-city" data-aos="fade-left" data-aos-delay="500">{product.location}</div>
              <div className="info-type"  data-aos-delay="500">{getPropertyTypeTranslation(product.type)}</div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick} data-aos="fade-right"
    />
  );
};

const PrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div
      className={className} data-aos="fade-left"
      onClick={onClick} 
    />
  );
};

export default HeaderHome;
