import React from 'react';
import Header_Vente from '../../components/Header_Vente/Header_Vente';
import Section1_Vente from '../../components/Section1_Vente/Section1_Vente';

const Vente = () => {
    return (
        <div className="Vente">
            <Header_Vente />
            <Section1_Vente />
        </div>
    );
}

export default Vente;