// Navbar.js
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { LanguageContext } from '../../context/LanguageContext';
import imageFr from '../../images/drapeau_france.jpg';
import imageEn from '../../images/drapeau-britannique.avif';
import translations from '../../translations';
import './Navbar.css';

const Navbar = ({ threshold }) => {
    const [isMobile, setIsMobile] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { language, setLanguage } = useContext(LanguageContext);
    const t = translations[language];

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.pageYOffset;
            setIsScrolled(offset > threshold);
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [threshold]);

    const handleLanguageChange = (lang) => {
        setLanguage(lang);
        setIsDropdownOpen(false); // Ferme le dropdown après la sélection de la langue
    };

    const handleLinkClick = () => {
        setIsMobile(false);
    };

    return (
        <nav className={`navbar ${isScrolled ? 'navbar-black' : 'navbar-transparent'}`}>
            <div className="nav-container">
                <h1 className="logo">Lentz Immobilier</h1>
                <div className={isMobile ? 'nav-menu-mobile' : 'nav-menu'}>
                    <div className="nav-item">
                        <Link to="/" onClick={handleLinkClick}>{t.home}</Link>
                    </div>
                    <div className="nav-item">
                        <Link to="/Vente" onClick={handleLinkClick}>{t.vente}</Link>
                    </div>
                    <div className="nav-item">
                        <Link to="/APropos" onClick={handleLinkClick}>{t.apropos}</Link>
                    </div>
                    <div className="nav-item">
                        <Link to="/Contact" onClick={handleLinkClick}>{t.contact}</Link>
                    </div>
                    <div className="nav-item">
                        <div className="dropdown">
                            <button className="dropbtn" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                                {language === 'fr' ? 'FR' : 'EN'} <i className="fa fa-caret-down"></i>
                            </button>
                            {isDropdownOpen && (
                                <div className="dropdown-content">
                                    <a href="#" onClick={() => handleLanguageChange('en')}>
                                        <img src={imageEn} alt="English" width="30" height="20" />
                                        English
                                    </a>
                                    <a href="#" onClick={() => handleLanguageChange('fr')}>
                                        <img src={imageFr} alt="French" width="30" height="20" />
                                        Français
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className={`mobile-menu-icon ${isMobile ? 'open' : ''}`} onClick={() => setIsMobile(!isMobile)}>
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
