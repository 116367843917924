import React, { createContext, useContext, useState } from 'react';

// Importez les images et vidéos nécessaires
import image1_1 from '../images/DJI_0561.jpg';
import image1_2 from '../images/DJI_0567.jpg';
import image1_3 from '../images/DSC00044.jpg';
import image1_4 from '../images/DSC00001.jpg';
import image1_5 from '../images/DSC00013.jpg';
import image1_6 from '../images/DSC00016.jpg';
import image1_7 from '../images/DSC00017.jpg';
import image1_8 from '../images/DSC00026.jpg';
import image1_9 from '../images/DSC00032.jpg';
import image1_10 from '../images/DSC00034.jpg';
import image1_11 from '../images/DSC00037.jpg';
import image1_12 from '../images/DSC00041.jpg';
import image1_13 from '../images/DSC00043.jpg';
import image1_14 from '../images/DSC00044.jpg';
import video1_1 from '../video/mont-des-oiseaux-3-2_C3XNNsE8.mp4';

import image2_1 from '../images/IMG_7946 3.jpg';
import image2_2 from '../images/IMG_7944 3.jpg';
import image2_3 from '../images/IMG_7945 2.jpg';
import image2_4 from '../images/IMG_7950 2.jpg';
import image2_5 from '../images/IMG_7947 3.jpg';
import image2_6 from '../images/IMG_7948 2.jpg';
import image2_7 from '../images/IMG_7949 2.jpg';
import image2_8 from '../images/IMG_7951.jpg';
import video2_1 from '../video/appartement-caroline-3-3_qIrnAcsi.mp4';


import video3_1 from '../video/villa-rayol-3_TPmPpsau.mp4';
import image3_1 from '../images/image3.jpg';

const ProductContext = createContext();

export const useProducts = () => {
    return useContext(ProductContext);
};

export const ProductProvider = ({ children }) => {
    const [products] = useState([
        {
            id: 1,
            carousselHome: image1_11,
            mainMedia: { type: 'image', src: image1_11 },
            carouselImages: [
                { type: 'image', src: image1_2 },
                { type: 'video', src: video1_1, thumbnail: video1_1 },
                { type: 'image', src: image1_3 },
                { type: 'image', src: image1_4 },
                { type: 'image', src: image1_5 },
                { type: 'image', src: image1_6 },
                { type: 'image', src: image1_7 },
                { type: 'image', src: image1_8 },
                { type: 'image', src: image1_9 },
                { type: 'image', src: image1_10 },
                { type: 'image', src: image1_1 },
                { type: 'image', src: image1_12 },
                { type: 'image', src: image1_13 },
                { type: 'image', src: image1_14 }
            ],
            type: "Villa",
            location: "Hyères",
            price: 2680000,
            superficie: "245 m²",
            terrain: "1879 m² - arboré",
            chambres: "3 (possibilité d’en créer 2 supplémentaires)",
            Piece: 4,
            Nombre_de_niveaux: 3,
            vue: "Vue mer",
            dependance: "studio",
            surfaceloicarre: "/ m²",
            ascenseur: "non",
        },
        {
            id: 2,
            carousselHome: image2_1,
            mainMedia: { type: 'image', src: image2_1 },
            carouselImages: [
                { type: 'image', src: image2_2 },
                { type: 'video', src: video2_1, thumbnail: video2_1 },
                { type: 'image', src: image2_3 },
                { type: 'image', src: image2_4 },
                { type: 'image', src: image2_5 },
                { type: 'image', src: image2_6 },
                { type: 'image', src: image2_7 },
                { type: 'image', src: image2_8 },
            ],
            type: "Appartement",
            location: "Toulon - Mourillon",
            price: 239000,
            superficie: "52 m²",
            surfaceloicarre: "52 m²",
            chambres: 2,
            terrain: "?",
            Piece: 3,
            Nombre_de_niveaux: 5,
            ascenseur: "oui",
            dependance: "cave",
            vue: "?",
        },
        {
            id: 3,
            carousselHome: image3_1,
            mainMedia: { type: 'video', src: video3_1 },
            carouselImages: [
                { type: 'image', src: image3_1 },
                { type: 'video', src: video3_1},
            ],
            type: "Villa",
            location: "Rayol-Canadel-sur-Mer",
            price: 1495000,
            superficie: "138 m2",
            surfaceloicarre: "?",
            terrain: "?",
            chambres: 4,
            Piece: 5,
            Nombre_de_niveaux: 2,
            vue: "?",
            ascenseur: "non",
            plus: "Plages à 5 minutes à pied",
        }
    ]);

    return (
        <ProductContext.Provider value={products}>
            {children}
        </ProductContext.Provider>
    );
};
