import React from 'react';
import Formulaire_Contact from '../../components/Formulaire_Contact/Formulaire_Contact';
import './Contact.css'


const Contact = () => {
    return (
        <div className="Contact">
            <Formulaire_Contact />
        </div>
    );
}

export default Contact;