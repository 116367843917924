import React, { useState, useEffect} from 'react';
import Header_home from '../../components/Header_home/Header_home'
import Section1_home from '../../components/Section1_home/Section1_home';
import Section2_home from '../../components/Section2_home/Section2_home';
import Section3_home from '../../components/Section3_home/Section3_home';
import CartSection_home from '../../components/CartSection_home/CartSection_home';

const Home = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            const offset = window.pageYOffset;
            setIsScrolled(offset > 425);
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div className="Home ">
            <Header_home />
            <Section1_home />
            <Section2_home />
            <Section3_home />
            <CartSection_home />
        </div>
    );
}

export default Home;