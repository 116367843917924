import React from 'react';
import './Honoraires.css';


const Honoraires = () => {
    
    return (
        <div>

            <h1>Honoraires</h1>
            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Similique, hic expedita rerum iure officia repudiandae beatae nam quas. Nam quos repudiandae earum architecto omnis libero corrupti ipsum natus dolor vitae!</p>
        </div>
    )
}

export default Honoraires;
