// translations.js
const translations = {
    en: {
        // __________Navbar________
        home: "Home",
        vente: "Sale",
        apropos: "About Us",
        contact: "Contact",
        // __________Header_Home__________
        loading: 'Loading...',
        propertyType: {
            Villa: 'Villa',
            Maison: 'House',
            Appartement: 'Apartment',
            // Traductions en anglais pour chaque type de propriété
        },
        // __________Section1_home ________
        quinos: "Who We Are",
        section1Title: "Welcome to Lentz immobilier",
        section1Description: "Find here a selection of properties for sale on the Var coast. I'm Corentin Lentz, the agency's founder, and I'm here to help you with any questions you may have about your property plans. Whether you're looking to move house, sell your property, get an accurate assessment of its current value or even invest, I'm here to help you every step of the way. My priority is to offer you quality advice and support, based on an in-depth knowledge of the property market and a clear understanding of your personal objectives. Choosing LENTZ IMMOBILIER means taking a step towards a new client experience, where your interests are my interests, so what's stopping you from trying? Translated with DeepL.com (free version)",
        // __________Section2_home 
        pourquoi: "Living in the Var",
        section2Description: "Discover the Var coastline, from Bandol to Saint Tropez. From the must-sees to the most popular districts, find the perfect place for your next home!",
        decouvrir: "Discover",
        // __________Section3_home 
        sales: "THE SALES",
        salesDescription: "Discover a selection of properties for sale in the Var at LENTZ IMMOBILIER.",
        viewVideo: "View Video",
        viewDetails: "View Details",
        // __________CartSection_home
        ourLocation: "Localisation",
        propertyForSale: "Property for Sale",
        luxuryHouseInParis: "Luxury House in Paris",
        price: "Price: €2,000,000",
        luxuryHouseDescription: "A luxury house located in the heart of Paris with all modern amenities.",
        // __________Section1_Vente
        luxuryRealEstate: "Properties for sale at LENTZ IMMOBILIER",
        propertyType: "Property Type:",
        all: "All",
        villa: "Villa",
        minPrice: "Minimum Price:",
        maxPrice: "Maximum Price:",
        reference: "Reference:",
        price: "Price:",
        area: "Area:",
        land: "Land:",
        bedrooms: "Bedrooms:",
        bathrooms: "Bathrooms:",
        learnMore: "Learn More",
        //___________ A_Propos
        commitment: "Why Lentz Immobilier ?",
        commitmentDescription: `LENTZ REAL ESTATE may not yet be a household name for you, but that will soon change.
        <br />
        <br />
        I am Corentin Lentz, founder of this real estate agency, created to offer my clients a unique expertise. LENTZ REAL ESTATE is the result of five years of alternating studies, covering various fields such as construction, renewable energy, promotion, and real estate transactions. But above all, it is the result of an overwhelming passion for entrepreneurship, a thirst for learning, and a desire to serve my clients in my own way.
        <br />
        <br />
        Why choose LENTZ REAL ESTATE? My youth and dynamism are my major assets. I am fully aware of the rapid changes in the real estate sector and know how to adapt to them.
        <br />
        <br />
        In the digital age, LENTZ REAL ESTATE stands out with an innovative approach. In addition to offering you all the traditional services of a real estate agency, we maximize, if you allow us, the visibility of your property on social networks and create memorable memories of your property. We produce professional video presentations in collaboration with an influential photographer/videographer in the Var, allowing us to find alternatives to traditional advertising media.
        <br />
        <br />
        Trust me to transform your real estate project into a unique and memorable experience, combining tradition and innovation.`,
        // _______________Formulaire_Contact
        contactUsForm: "Contact Us",
        firstName: "First Name",
        lastName: "Last Name",
        contactEmail: "Email",
        contactPhone: "Phone",
        subject: "Subject",
        message: "Message",
        send: "Send",
        sending: "Sending...",
        success: "Message successfully sent!",
        recaptcha: "Please complete the reCAPTCHA",
         //______________VenteProductDetails
        sale: "Sale",
        type: "type : ",
        productNotFound: "Product not found : ",
        location : "location : ",
        price: "Price : ",
        superficie: "Surface area : ",
        surfaceloicarre:"Carrez law surface area  : ",
        terrain: "Land area : ",
        chambres: "Bedrooms : 3 ",
        piece: "Rooms : ",
        nombre_de_niveaux: "Number of levels : ",
        vue: "View : ",
        dependance: "Dependence : ",
        plus: "Additional information : ",
        description: "Description : ",
        ascenseur:"lift : ",
        // __________Footer
        about: "MonImmobilier is your trusted source for finding the best properties to buy, sell, or rent. We offer top-quality services and expertise to help you achieve your real estate dreams.",
        followUs: "Follow me",
        contactUs: "Contact me",
        phone: "+33 6 13 53 24 81",
        email: "lentzimmobilier@gmail.com",
        address: "Toulon",
        honoraires: "Fees",
        mentionsLégales: "Legal Notice",
        allRightsReserved: "All rights reserved"
    },
    fr: {
        // __________Navbar________
        home: "Accueil",
        vente: "Vente",
        apropos: "À Propos",
        contact: "Contact",
        // __________Header_Home__________
        loading: 'Chargement...',
        propertyType: {
            Villa: 'Villa',
            Maison: 'Maison',
            Appartement: 'Appartement',
            // Ajoutez d'autres types de propriété selon votre besoin
        },
        // __________Section1_home ________
        section1Title: "Bienvenue chez Lentz immobilier",
        section1Description: `Retrouvez ici une sélection de biens à vendre sur le littoral Varois. Je suis Corentin Lentz, fondateur de l’agence, et je suis à votre écoute pour toute demande concernant votre projet immobilier.
        <br>
        <br> Que vous envisagiez de changer de lieu d’habitation, de vendre votre bien, d’obtenir une évaluation précise de sa valeur actuelle ou même d’investir, je suis là pour vous accompagner à chaque étape de votre projet.
        <br>
        <br>Ma priorité est de vous offrir des conseils et un soutien de qualité, basés sur une connaissance approfondie du marché immobilier et une compréhension précise de vos objectifs personnels.
        <br>
        <br> Choisir LENTZ IMMOBILIER, c’est franchir un pas vers une expérience client nouvelle, où vos intérêts sont les miens, alors qu’est-ce qui vous retient d’essayer ?`,
        // __________Section2_home 
        pourquoi: "Vivre dans le var",
        section2Description: "Découvrez le littoral varois, depuis Bandol jusque Saint Tropez. Les incontournables, les quartiers les plus prisés, trouvez le lieu idéal pour votre prochain chez vous !",
        decouvrir: "Découvrir",
        // __________Section3_home 
        sales: "LES VENTES",
        salesDescription: "Découvrez une sélection de biens à vendre dans le var chez LENTZ IMMOBILIER.",
        viewVideo: "Voir la vidéo",
        viewDetails: "Voir Détails",
        //___________CartSection_home
        ourLocation: "Localisation",
        propertyForSale: "Propriété à Vendre",
        luxuryHouseInParis: "Maison de Luxe à Paris",
        price: "Prix: €2,000,000",
        luxuryHouseDescription: "Une maison de luxe située au cœur de Paris avec toutes les commodités modernes.",
        // __________Section1_Vente
        luxuryRealEstate: "Les biens en vente chez LENTZ IMMOBILIER",
        propertyType: "Type de propriété:",
        all: "Tous",
        villa: "Villa",
        minPrice: "Prix minimum:",
        maxPrice: "Prix maximum:",
        reference: "Référence :",
        price: "Prix :",
        area: "Superficie :",
        land: "Terrain :",
        bedrooms: "Chambres :",
        bathrooms: "Salles de bain :",
        learnMore: "En Savoir Plus",
        //____________ A_Propos
        commitment: "Pourquoi Lentz Immobilier ?",
        commitmentDescription: `LENTZ IMMOBILIER peut ne pas être encore un nom familier pour vous, mais cela ne tardera pas
        à changer.
        <br />
        <br />
        Je suis Corentin Lentz, fondateur de cette agence immobilière, créée pour offrir à mes clients une
        expertise unique. LENTZ IMMOBILIER est le fruit de cinq années d'études en alternance, couvrant
        divers domaines tels que la construction, les énergies renouvelables, la promotion et la transaction
        immobilière. Mais avant tout, c'est le résultat d'une passion débordante pour l'entrepreneuriat, une
        soif d'apprendre et de servir mes clients à ma manière.
        <br />
        <br />
        Pourquoi choisir LENTZ IMMOBILIER ? Ma jeunesse et mon dynamisme sont mes atouts
        majeurs. Je suis parfaitement conscient des évolutions rapides du secteur immobilier et je sais
        comment m'y adapter.
        <br />
        <br />
        À l'ère du digital, LENTZ IMMOBILIER se distingue par une approche innovante. En plus de vous
        offrir tous les services traditionnels d'une agence immobilière, nous maximisons si vous nous
        l’accorder la visibilité de votre bien sur les réseaux sociaux et créons des souvenirs mémorables de
        votre propriété. Nous produisons des présentations vidéo professionnelles en collaboration avec un
        photographe/vidéaste influent dans le Var, ce qui nous permet de trouver des alternatives aux
        supports publicitaires classiques.
        <br />
        <br />
        Faites moi confiance pour transformer votre projet immobilier en une expérience unique et
        mémorable, alliant tradition et innovation.`,
        // Formulaire_Contact
        contactUsForm: "Contactez-Nous",
        firstName: "Prénom",
        lastName: "Nom",
        contactEmail: "Email",
        contactPhone: "Téléphone",
        subject: "Sujet",
        message: "Message",
        send: "Envoyer",
        sending: "Envoi en cours...",
        success: "Message envoyé avec succès !",
        recaptcha: "Veuillez compléter le reCAPTCHA",
        // VenteProductDetails
        sale: "Vente : ",
        surfaceloicarre:"Surface loi Carrez : ",
        type: "type : ",
        location : "location : ",
        productNotFound: "Produit non trouvé : ",
        price: "Prix : ",
        superficie: "Superficie : ",
        terrain: "Terrain : ",
        chambres: "Chambres : ",
        piece: "Pièces : ",
        nombre_de_niveaux: "Nombre de niveaux : ",
        vue: "Vue : ",
        dependance: "Dépendance : ",
        plus: "Informations supplémentaires : ",
        description: "Description : ",
        ascenseur:"ascenseur : ",
        // __________Footer
        about: "MonImmobilier est votre source de confiance pour trouver les meilleures propriétés à acheter, vendre ou louer. Nous offrons des services de qualité supérieure et une expertise pour vous aider à réaliser vos rêves immobiliers.",
        followUs: "Suivez-moi",
        contactUs: "Contactez-moi",
        phone: "+33 6 13 53 24 81",
        email: "lentzimmobilier@gmail.com",
        address: "Toulon",
        honoraires: "Honoraires",
        mentionsLégales: "Mentions Légales",
        allRightsReserved: "Tous droits réservés",
    }
};

export default translations;
