import React from 'react';
import './Error404.css';

const Error404 = () => {
    return (
        <div className="error-container">
            <img 
                src="https://i.imgur.com/qIufhof.png" 
                alt="Page Not Found" 
                className="error-image"
            />
            <h1>404 - Page Not Found</h1>
            <p>La page que vous recherchez n'existe pas.</p>
        </div>
    );
};

export default Error404;